import React from "react";

function TermsConditions() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 text-gray-800">
      {/* Title and Last Updated */}
      <h1 className="text-3xl font-bold mb-2 text-center">
        MD VOICE AI LANDING APPLICATION TERMS AND CONDITIONS OF USE
      </h1>
      <p className="text-sm text-gray-600 mb-6 text-center">
        Last Updated: January 02, 2025
      </p>

      {/* 1. Acceptance of the Terms and Conditions of Use */}
      <h2 className="text-xl font-semibold mb-2">
        Acceptance of the Terms and Conditions of Use
      </h2>
      <p className="mb-4">
        These terms and conditions of use for mdvoice.ai, constitute a legal
        agreement and are entered into by and between you and MD Voice AI Inc.
        ("Company," "we," "us," "our"). The following terms and conditions,
        together with any documents and/or additional terms they expressly
        incorporate by reference, including the competition specific terms and
        conditions (collectively, these "Terms and Conditions"), govern your
        access to and use, including any content, functionality, and services
        offered on or through our application, MD Voice, our website,
        mdvoice.ai, and our Chrome extension facilitating access between medical
        professionals and Electronic Medical Records (collectively, the
        "Application").
      </p>
      <p className="mb-4">
        By using the Application, or by clicking to accept the terms and
        conditions, you accept and agree to be bound and comply with these terms
        and conditions and our privacy policy incorporated herein by reference.
        If you do not agree to these terms and conditions or the privacy policy,
        you must not access or use the Application.
      </p>

      {/* 2. User Representation */}
      <h2 className="text-xl font-semibold mb-2">User Representation</h2>
      <p className="mb-4">
        By using this Application and/or accessing our services through this
        Application, you represent and warrant that you are the legal age of
        majority under applicable law to form a binding contract with the
        Company and meet all of the foregoing eligibility requirements. You
        represent and warrant that you are above the age of majority and are a
        licensed healthcare professional under your applicable laws and
        regulatory requirements.
      </p>

      {/* 3. Modifications to the Terms and Conditions and to the Application */}
      <h2 className="text-xl font-semibold mb-2">
        Modifications to the Terms and Conditions and to the Application
      </h2>
      <p className="mb-4">
        We reserve the right in our sole discretion to revise and update these
        terms and conditions from time to time. Any and all such modifications
        are effective immediately upon posting and apply to all access to and
        continued use of the Application. You agree to periodically review the
        terms and conditions in order to be aware of any such modifications and
        your continued use shall be your acceptance of these. We will not be
        liable if, for any reason, all or any part of the Application is
        restricted to users or unavailable at any time or for any period.
      </p>

      {/* 4. Your Use of the Application and Security */}
      <h2 className="text-xl font-semibold mb-2">
        Your Use of the Application and Security
      </h2>
      <p className="mb-4">
        We use physical, electronic, and administrative measures designed to
        secure your personal information from accidental loss and from
        unauthorized access, use, alteration, and disclosure. Users are
        responsible for obtaining their own access to the Application. Users are
        required to ensure that all persons who access the Application through a
        user's internet connection are aware of these Terms and Conditions and
        comply with them. The Application, including content or areas of the
        Application or creating a User Account, may require user registration
        and the submission of personal information including, name, email, and
        phone number (the “User Account”). It is a condition of your use of the
        Application that all the information you provide on the Application is
        correct, current, and complete. You are responsible for maintaining the
        confidentiality of your account credentials for your User Account.
      </p>
      <p className="mb-4">
        Any transmission of personal information is at your own risk. We are not
        responsible for circumvention of any privacy settings or security
        measures contained on the Application. You are responsible at your own
        expense for obtaining your own Internet access and any hardware and
        software required therefor to security connect with the Application.
        Your provision of registration information and any submissions you make
        to the Application through any functionality (the "Interactive
        Functions") constitutes your consent to all actions we take with respect
        to such information consistent with our Privacy Policy, found at{" "}
        <a
          href="https://mdvoice.ai/privacy-policy"
          className="text-blue-500 underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://mdvoice.ai/privacy-policy
        </a>
        .
      </p>
      <p className="mb-4">
        You are prohibited from attempting to circumvent and from violating the
        security of this Application, including, without limitation:
      </p>
      <ul className="list-disc list-inside mb-4 space-y-1">
        <li>(a) accessing unauthorized content and;</li>
        <li>
          (b) attempting to breach or breaching the security and/or
          authentication measures;
        </li>
        <li>(c) restricting, disrupting or disabling service;</li>
        <li>(d) illicitly reproducing TCP/IP packet header;</li>
        <li>
          (e) disrupting network services and otherwise disrupting Application
          owner's ability to monitor the Application;
        </li>
        <li>
          (f) using any robot, spider, or other automatic device, process, or
          means to access the Application for any purpose, including monitoring
          or copying any of the material on the Application;
        </li>
        <li>
          (g) introducing any viruses, trojan horses, worms, logic bombs, or
          other material that is malicious or technologically harmful;
        </li>
        <li>
          (h) attacking the Application via a denial-of-service attack,
          distributed denial-of-service attack, flooding, mailbombing, or
          crashing;
        </li>
        <li>
          (i) otherwise attempting to interfere with the proper working of the
          Application.
        </li>
      </ul>

      {/* 5. Intellectual Property Rights and Ownership */}
      <h2 className="text-xl font-semibold mb-2">
        Intellectual Property Rights and Ownership
      </h2>
      <p className="mb-4">
        You understand and agree that the Application and its entire contents,
        features, and functionality, including, but not limited to, all
        information, software, code, text, displays, graphics, photographs,
        video, audio, design, presentation, selection, and arrangement
        (collectively “Content”), are owned by the Company, its licensors, or
        other providers of such material and are protected in all forms by
        intellectual property laws including without limitation, copyright,
        trademark, patent, trade secret, and any other proprietary rights.
      </p>
      <p className="mb-4">
        You must not use such marks without the express prior written permission
        of the Company. Other names, logos, product and service names, designs,
        images, and slogans mentioned, or which appear on this Application are
        the trademarks of their respective owners. If you are granted permission
        by us, you agree to use the specific Content that we allow and ONLY in
        the ways for which we have given you our written permission. If you
        choose to use the Content in ways that we do not specifically give you
        written permission, you consent to immediately stop using such Content
        and to take whatever actions as we may request. Any request for written
        permission to use our Content, or any other intellectual property or
        property belonging to us, should be made before you wish to use the
        Content by sending an e-mail to support@mdvoice.ai.
      </p>
      <p className="mb-4">
        You may only use the Application for your personal and non-commercial
        use. You shall not directly or indirectly reproduce, compile for an
        internal database, distribute, modify, create derivative works of,
        publicly display, publicly perform, republish, download, store, or
        transmit any of the material on our Application, in any form or medium
        whatsoever except:
      </p>
      <ul className="list-disc list-inside mb-4 space-y-1">
        <li>
          (a) your computer and browser may temporarily store or cache copies of
          materials being accessed and viewed;
        </li>
        <li>
          (b) a reasonable number of copies for personal use only may be printed
          keeping any proprietary notices thereon, which may only be used for
          non-commercial and lawful personal use; and
        </li>
        <li>
          (c) one single user copy may be downloaded with any proprietary
          notices intact, for your own personal, non-commercial use, conditional
          on your agreement to be bound by our end user licence agreement for
          such downloads.
        </li>
      </ul>

      {/* 6. Conditions of Use */}
      <h2 className="text-xl font-semibold mb-2">Conditions of Use</h2>
      <p className="mb-4">
        As a condition of your access and use, you agree that you may use the
        Application only for lawful purposes and in accordance with these Terms
        and Conditions. The following content standards apply to any and all
        content, material, and information a user submits, posts, publishes,
        displays, or transmits (the “Submissions”) to the Application, and any
        and all Interactive Functions.
      </p>
      <p className="mb-4">
        Without limiting the foregoing, you warrant and agree that your use of
        the Application and any Submissions shall not:
      </p>
      <ul className="list-disc list-inside mb-4 space-y-1">
        <li>
          (a) In any manner violate any applicable laws or could give rise to
          any civil or criminal liability, or that otherwise may be in conflict
          with these Terms and Conditions and our Privacy Policy found at{" "}
          <a
            href="https://mdvoice.ai/privacy-policy"
            className="text-blue-500 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://mdvoice.ai/privacy-policy
          </a>
          .
        </li>
        <li>
          (b) In any manner violate the terms of use of any third-party
          Application that is linked to the Application, if any.
        </li>
        <li>
          (c) Include or contain any material that is exploitive, obscene,
          harmful, threatening, abusive, harassing, hateful, defamatory,
          sexually explicit or pornographic, violent, inflammatory, or
          discriminatory based on any legally prohibited ground or be otherwise
          objectionable, such determination to be made in Company's sole
          discretion.
        </li>
        <li>
          (d) Involve stalking, attempting to exploit or harm any individual
          (including minors) in any way by exposing them to inappropriate
          content or otherwise or ask for personal information as prohibited
          under applicable laws, regulations, or code.
        </li>
        <li>
          (e) Involve, provide, or contribute any false, inaccurate, or
          misleading information.
        </li>
        <li>
          (f) Impersonate or attempt to impersonate the Company, a Company
          employee, another user, or any other person or entity (including,
          without limitation, by using email addresses, or screen names
          associated with any of the foregoing).
        </li>
        <li>
          (g) Transmit, or procure the sending of, any advertisements or
          promotions without our prior written consent, sales, or encourage any
          other commercial activities.
        </li>
        <li>
          (h) Encourage any other conduct that restricts or inhibits anyone's
          use or enjoyment of the Application, or which, as determined by us,
          may harm the Company or users of the Application or expose them to
          liability.
        </li>
        <li>
          (i) Cause annoyance, inconvenience, or needless anxiety or be likely
          to upset, embarrass, or alarm any other person.
        </li>
        <li>
          (j) Promote any illegal activity, or advocate, promote, or assist any
          unlawful act.
        </li>
        <li>
          (k) Give the impression that they originate from or are endorsed by us
          or any other person or entity, if this is not the case.
        </li>
        <li>
          (l) Provide or input any sensitive information, personal information,
          or personal health information of any of your patients, clients,
          employees, staff or any other similar persons without the requisite
          consent under the applicable laws.
        </li>
        <li>
          (m) Use any unauthorized robot, spider, scraper or other automated
          means to access the Service, or engage in any scraping, data-mining,
          harvesting, data aggregating or indexing of the Application or any of
          its related services.
        </li>
      </ul>

      {/* 7. Site Monitoring and Enforcement, Suspension, and Termination */}
      <h2 className="text-xl font-semibold mb-2">
        Site Monitoring and Enforcement, Suspension, and Termination
      </h2>
      <p className="mb-4">
        Company has the right, without provision of notice to:
      </p>
      <ul className="list-disc list-inside mb-4 space-y-1">
        <li>
          (a) Remove or refuse to post on the Application any Submissions for
          any or no reason;
        </li>
        <li>
          (b) At all times, take such actions with respect to any Submission;
        </li>
        <li>
          (c) Take appropriate legal action, including, without limitation,
          referral to law enforcement or regulatory authority, or notifying the
          harmed party of any illegal or unauthorized use of the Application;
          and
        </li>
        <li>
          (d) Terminate or suspend your access to all or part of the Application
          for any or no reason, including, without limitation, any violation of
          these Terms and Conditions.
        </li>
      </ul>
      <p className="mb-4">
        You waive and hold harmless the company and its parent, subsidiaries,
        affiliates, and their respective directors, officers, employees, agents,
        service providers, contractors, licensors, licensees, suppliers, and
        successors from any and all claims resulting from any action taken by
        the company and any of the foregoing parties relating to any,
        investigations by either the company or such parties or by law
        enforcement authorities. We have no obligation, nor any responsibility
        to any party to monitor the Application or its use, and do not and
        cannot undertake to review material that you or other users submit to
        the Application.
      </p>

      {/* 8. No Reliance */}
      <h2 className="text-xl font-semibold mb-2">No Reliance</h2>
      <p className="mb-4">
        The content on our Application is provided for general information
        purposes only. It is not intended to amount to advice on which you
        should rely. You must obtain more specific or professional advice before
        taking, or refraining from, any action or inaction on the basis of the
        content on our site. Although we make reasonable efforts to update the
        information on our Application, we make no representations, warranties,
        or guarantees, whether express or implied, that the content on our
        Application is accurate, complete, or up to date. Your use of the
        Application is at your own risk and neither the Company nor its parent,
        subsidiaries, affiliates, and their respective directors, officers,
        employees, agents, service providers, contractors, licensors, licensees,
        suppliers, or successors have any responsibility or liability whatsoever
        for your use of this Application.
      </p>

      {/* 9. Contests, Sweepstakes and Promotions */}
      <h2 className="text-xl font-semibold mb-2">
        Contests, Sweepstakes and Promotions
      </h2>
      <p className="mb-4">
        From time to time, the Company, may conduct promotions through the
        Application, including, without limitation, contests and sweepstakes
        (“Promotions”). Each Promotion may have additional terms and/or rules
        which will be deemed incorporated into and form a part of this
        Agreement. Without limiting the generality of the foregoing, the Company
        will conduct a monthly draw on or about the 15th day of the month from
        the names of User Accounts for a prize determined at the sole discretion
        of the company. By using this Application and by creating a User
        Account, you agree and consent to have your name displayed on the
        Application for promotional purposes related to the Company and its
        Promotions. If you would not like to have your name displayed on the
        Application, you have the option to opt out by changing your User
        Account Settings or emailing the company at{" "}
        <a href="mailto:support@mdvoice.ai" className="text-blue-500 underline">
          support@mdvoice.ai
        </a>
        .
      </p>

      {/* 10. Privacy */}
      <h2 className="text-xl font-semibold mb-2">Privacy</h2>
      <p className="mb-4">
        By submitting your personal information and using our Application, you
        consent to the collection, use, reproduction, hosting, transmission, and
        disclosure of any such user content submissions in compliance with our
        Privacy Policy, found at{" "}
        <a
          href="https://mdvoice.ai/privacy-policy"
          className="text-blue-500 underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://mdvoice.ai/privacy-policy
        </a>
        , as we deem necessary for use of the Application and provision of
        services. By using this Application, you are consenting to the use of
        cookies which allow a server to recall previous requests or registration
        and/or IP addresses to analyze Application use patterns. By using this
        Application, you also consent to the Company’s medical professional
        clients receiving and sharing personal information, personal health
        information, and patient data using a Chrome extension to access
        Electronic Medical Records (“EMR”). The Chrome extension will only
        access and update specific EMR information with consent and
        authorization from the Company’s medical professional clients in a
        Session-Based Access manner. For clarity, this Chrome extension is
        specifically configured to access and update designated EMR information
        solely with the explicit consent and authorization granted by the
        Company's medical professional clients. For more information on this
        automated information gathering practices, see{" "}
        <a
          href="https://mdvoice.ai/privacy-policy"
          className="text-blue-500 underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://mdvoice.ai/privacy-policy
        </a>
        .
      </p>

      {/* 11. Third-Party Applications */}
      <h2 className="text-xl font-semibold mb-2">Third-Party Applications</h2>
      <p className="mb-4">
        For your convenience, this Application may provide links or pointers to
        third-party sites. We make no representations about any other
        Applications that may be accessed from this Application. If you choose
        to access any such sites, you do so at your own risk. We have no control
        over the contents of any such third-party sites and accept no
        responsibility for such sites or for any loss or damage that may arise
        from your use of them. You are subject to any terms and conditions and
        policies of such third-party sites including, but not limited to, the
        sites of third-party advertisers whom the Company allows to display
        their advertisement on the Application, and the Electronic Medical
        Records systems for medical professionals requiring Session-Based
        Access.
      </p>
      <p className="mb-4">
        The Company will partner with a third-party AI company to generate
        internal reports and notes. You will not have any access to the
        software, AI model, or other technology belonging to the third party
        company AI company. You may link to our homepage, provided you do so in
        a way that is fair and legal and does not damage our reputation or take
        advantage of it.
      </p>

      {/* 12. Online Purchases */}
      <h2 className="text-xl font-semibold mb-2">Online Purchases</h2>
      <p className="mb-4">
        All orders, purchases or transactions for the sale of any goods,
        services, or information made using any third-party Applications, which
        you may be redirected to by clicking a link on this Application, are
        subject to the terms and conditions of such third-party Applications,
        including and not limited to the terms and conditions of the payment
        processor Stripe, Inc., or any other Application the company may choose.
        Additional terms and conditions may be applicable to parts or features
        of this Application and are hereby incorporated by reference into these
        Terms and Conditions.
      </p>

      {/* 13. Payments for Subscription Service */}
      <h2 className="text-xl font-semibold mb-2">
        Payments for Subscription Service
      </h2>
      <p className="mb-4">
        If using a paid subscription plan, you will provide the company (or its
        third-party payment service provider) with a valid credit card or
        payment method available by Stripe Inc. for payment of the applicable
        subscription fees. The Company reserves the right to increase its fees
        upon at least 30 days’ advance notice (e-mail or otherwise) to you;
        provided, however, that fee increases will not take effect until the
        start of the next subscription term. All amounts due hereunder are
        exclusive of all sales, use, excise, service, value added, or other
        taxes, duties and related charges of any kind. Any and all payments by
        or on account of the compensation payable under any subscription service
        shall be made free and clear of and without deduction or withholding for
        any taxes.
      </p>

      {/* 14. Subscription Termination */}
      <h2 className="text-xl font-semibold mb-2">Subscription Termination</h2>
      <p className="mb-4">
        You are solely responsible for cancelling subscriptions. You may cancel
        your subscription at any time by emailing the Company or cancelling the
        subscription under the settings of your User Account. After the initial
        subscription term, this term of the subscription shall be deemed renewed
        automatically at the end of the term, for the same term, until the
        service is cancelled. The Company will terminate your subscription
        service if you have failed to make the required payments, if applicable,
        after three (3) months from the due date of such payments. If the
        Company has not received any communications from you following another
        three (3) months from the date your subscription is cancelled for
        non-payment, then the Company will terminate your User Account and
        delete all data associated with your User account.
      </p>

      {/* 15. No Refunds */}
      <h2 className="text-xl font-semibold mb-2">No Refunds</h2>
      <p className="mb-4">
        All sales are final, and the Company does not offer any money-back
        guarantees. You recognize and agree that you shall not be entitled to a
        refund for any purchase under any circumstances. Not limiting the
        generality of the foregoing, if you cancel a paid monthly plan before
        the completion of the last month paid for by the monthly plan (the “Paid
        Month”), the Company will return the amount for the Paid Month
        calculated on a pro-rated basis for the amount of remaining days in the
        month.
      </p>

      {/* 16. Geographic Restrictions */}
      <h2 className="text-xl font-semibold mb-2">Geographic Restrictions</h2>
      <p className="mb-4">
        The owner of the Application is based in the Province of Ontario in
        Canada. If you access the Application from outside Canada, you do so at
        your own risk and you are responsible for compliance with local laws of
        your jurisdiction.
      </p>

      {/* 17. Disclaimer of Warranties */}
      <h2 className="text-xl font-semibold mb-2">Disclaimer of Warranties</h2>
      <p className="mb-4">
        You understand and agree that your use of the Application, its content,
        and any services (including third party services) or items found or
        attained through the Application is at your own risk. The Application,
        its content, and any services or items found or attained through the
        Application are provided on an "as is" and "as available" basis, without
        any warranties or conditions of any kind, either express or implied
        including, but not limited to, the implied warranties of
        merchantability, fitness for a particular purpose, or non-infringement.
        The foregoing does not affect any warranties that cannot be excluded or
        limited under applicable law.
      </p>
      <p className="mb-4">
        Neither the company nor its parent, subsidiaries, affiliates, or their
        respective directors, officers, employees, agents, service providers,
        contractors, licensors, licensees, suppliers, or successors make any
        warranty, representation, or endorsement with respect to the
        completeness, security, reliability, suitability, accuracy, currency, or
        availability of the Application or its contents. Without limiting the
        foregoing, neither the company nor its parent, subsidiaries, affiliates
        or their respective directors, officers, employees, agents, service
        providers, contractors, licensors, licensees, suppliers, or successors
        represent or warrant that the Application, its content, or any services
        or items found or attained through the Application will be accurate,
        reliable, error-free, or uninterrupted, that defects will be corrected,
        that our Application or the server that makes it available are free of
        viruses or other harmful components.
      </p>
      <p className="mb-4">
        We cannot and do not guarantee or warrant that files or data available
        for downloading from the internet or the Application will be free of
        viruses or other destructive code. You are solely and entirely
        responsible for your use of the Application and your computer, internet,
        and data security. To the fullest extent provided by law, we will not be
        liable for any loss or damage caused by denial-of-service attack,
        distributed denial-of-service attack, overloading, flooding,
        mailbombing, or crashing, viruses, trojan horses, worms, logic bombs, or
        other technologically harmful material that may infect your computer
        equipment, computer programs, data, or other proprietary material due to
        your use of the Application or any services or items found or attained
        through the Application or to your downloading of any material posted on
        it, or on any Application linked to it.
      </p>

      {/* 18. Limitation on Liability */}
      <h2 className="text-xl font-semibold mb-2">Limitation on Liability</h2>
      <p className="mb-4">
        Except where such exclusions are prohibited by law, under no
        circumstance will the company nor its parent, subsidiaries, affiliates
        or their respective directors, officers, employees, agents, service
        providers, contractors, licensors, licensees, suppliers, or successors
        be liable for negligence, gross negligence, negligent misrepresentation,
        fundamental breach, damages of any kind, under any legal theory,
        including any direct, indirect, special, incidental, consequential, or
        punitive damages, including, but not limited to, personal injury, pain
        and suffering, emotional distress, loss of revenue, loss of profits,
        loss of business or anticipated savings, loss of use, loss of goodwill,
        loss of data, and whether caused by tort (including negligence), breach
        of contract, breach of privacy, or otherwise, even if the party was
        allegedly advised or had reason to know, arising out of or in connection
        with your use, or inability to use, or reliance on, the Application, any
        linked Applications or such other third-party Applications, nor any
        Application content, materials, posting, or information thereon even if
        the party was allegedly advised or had reason to know.
      </p>

      {/* 19. Indemnification */}
      <h2 className="text-xl font-semibold mb-2">Indemnification</h2>
      <p className="mb-4">
        To the maximum extent permitted by applicable law, you agree to defend,
        indemnify, and hold harmless the Company, its parent, subsidiaries,
        affiliates, and their respective directors, officers, employees, agents,
        service providers, contractors, licensors, suppliers, successors, and
        assigns from and against any claims, liabilities, damages, judgments,
        awards, losses, costs, expenses, or fees (including reasonable
        attorneys' fees) arising out of or relating to your breach of these
        Terms and Conditions or your use of the Application, including, but not
        limited to, your Submissions, third-party sites, any use of the
        Application's content, services, and products other than as expressly
        authorized in these Terms and Conditions.
      </p>

      {/* 20. Governing Law and Choice of Forum */}
      <h2 className="text-xl font-semibold mb-2">
        Governing Law and Choice of Forum
      </h2>
      <p className="mb-4">
        The Application and these Terms and Conditions will be governed by and
        construed in accordance with the laws of the of Canada, without giving
        effect to any choice or conflict of law provision, and notwithstanding
        your domicile, residence, or physical location. Any action or proceeding
        arising out of or relating to this Application and under these Terms and
        Conditions will be instituted in the courts of the Province of Ontario
        and/or the Federal Court of Canada, and each party irrevocably submits
        to the exclusive jurisdiction of such courts in any such action or
        proceeding. You waive any and all objections to the exercise of
        jurisdiction over you by such courts and to the venue of such courts.
      </p>

      {/* 21. Arbitration */}
      <h2 className="text-xl font-semibold mb-2">Arbitration</h2>
      <p className="mb-4">
        You hereby expressly waive any and all claims you may have, now or in
        the future, arising out of or relating to this Application, the Company,
        any and all contracts you enter into with the Company, and any and all
        of the Company’s products and services. To the extent that you attempt
        to assert any such claim, you hereby expressly agree to present such
        claim only through binding arbitration to occur in the Province of
        Ontario. You further agree to and do hereby waive any right to class
        arbitration and agree, instead, to conduct an arbitration related solely
        to any individual claims you and/or any entity related to you asserts
        against the Company. To the fullest extent permissible by law, you
        further agree that you shall be responsible for all costs associated
        with initiating the arbitration and for the administration of the
        arbitration.
      </p>

      {/* 22. International Users */}
      <h2 className="text-xl font-semibold mb-2">International Users</h2>
      <p className="mb-4">
        The Service is operated by the Company from our offices within Canada.
        If you access the Service from a location outside Canada, you are
        responsible for compliance with all local laws. You agree that you will
        not use the Company Content accessed through the Application in any
        country or in any manner prohibited by any applicable laws, restrictions
        or regulations.
      </p>

      {/* 23. Termination and Access Restriction from Application */}
      <h2 className="text-xl font-semibold mb-2">
        Termination and Access Restriction from Application
      </h2>
      <p className="mb-4">
        The Company reserves the right, in its sole discretion, to terminate
        your access to the Application and the related services or any portion
        thereof at any time, without notice. To the maximum extent permitted by
        law, and you hereby consent to resolve any and all disputes arising
        under or related to this Application or the Terms and Conditions
        pursuant to the Arbitration Clause above. Use of the Application is
        unauthorized in any jurisdiction that does not give effect to all
        provisions of these Terms and Conditions, including, without limitation,
        this section.
      </p>

      {/* 24. No Joint Venture or Other Relationship */}
      <h2 className="text-xl font-semibold mb-2">
        No Joint Venture or Other Relationship
      </h2>
      <p className="mb-4">
        You agree that no joint venture, partnership, employment, or agency
        relationship exists between you and the Company as a result of this
        agreement or use of the Application.
      </p>

      {/* 25. Waiver */}
      <h2 className="text-xl font-semibold mb-2">Waiver</h2>
      <p className="mb-4">
        No failure to exercise, or delay in exercising, any right, remedy,
        power, or privilege arising from these Terms and Conditions operates, or
        may be construed, as a waiver thereof. No single or partial exercise of
        any right, remedy, power, or privilege hereunder precludes any other or
        further exercise thereof or the exercise of any other right, remedy,
        power, or privilege.
      </p>

      {/* 26. Severability */}
      <h2 className="text-xl font-semibold mb-2">Severability</h2>
      <p className="mb-4">
        If any term or provision of these Terms and Conditions is invalid,
        illegal, or unenforceable in any jurisdiction, such invalidity,
        illegality, or unenforceability shall not affect any other term or
        provision of these Terms and Conditions or invalidate or render
        unenforceable such term or provision in any other jurisdiction.
      </p>

      {/* 27. Entire Agreement */}
      <h2 className="text-xl font-semibold mb-2">Entire Agreement</h2>
      <p className="mb-4">
        The Terms and Conditions and our Privacy Policy constitute the sole and
        entire agreement between you and the Company regarding the Application
        and supersedes all prior and contemporaneous understandings, agreements,
        representations and warranties, both written and oral, regarding such
        subject matter.
      </p>

      {/* 28. Reporting and Contact */}
      <h2 className="text-xl font-semibold mb-2">Reporting and Contact</h2>
      <p className="mb-4">
        This Application is operated by MD Voice AI Inc. Should you become aware
        of misuse of the Application including libelous or defamatory conduct,
        you must report it to the Company at{" "}
        <a href="mailto:support@mdvoice.ai" className="text-blue-500 underline">
          support@mdvoice.ai
        </a>
        .
      </p>
      <p className="mb-4">
        All other feedback, comments, requests for technical support, and other
        communications relating to the Application should be directed to{" "}
        <a href="mailto:support@mdvoice.ai" className="text-blue-500 underline">
          support@mdvoice.ai
        </a>
        .
      </p>
    </div>
  );
}

export default TermsConditions;
