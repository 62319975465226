import React from "react";

function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 text-gray-800">
      {/* Title and Effective Date */}
      <h1 className="text-3xl font-bold mb-2 text-center">
        MD VOICE AI PRIVACY POLICY
      </h1>
      <p className="text-sm text-gray-600 mb-6 text-center">
        Effective Date: January 02, 2025
      </p>

      {/* Introduction */}
      <h2 className="text-xl font-semibold mb-2">Introduction</h2>
      <p className="mb-4">
        MD Voice AI Inc. (“Company,” “we,” “us,” “our”) has a practice of
        protecting the privacy and security of customer, supplier and employee
        records. We are committed to meeting our obligations under Canadian data
        privacy laws, including the Personal Information Protection and
        Electronic Documents Act, S.C. 2000, c. 5 (PIPEDA), and applicable
        provincial laws. We are also committed to meeting our obligations under
        the US Health Insurance Portability and Accountability Act (HIPAA). We
        adhere to the privacy principles set out below, which govern the way we
        collect, use, store and disclose personal information that is obtained
        in the course of development, sales, promotion and distribution of our
        products or in the course of employment.
      </p>
      <p className="mb-4">
        “Personal information”, as used in this policy, means any information
        about an identifiable person, including but not limited to name,
        customer and supplier information, ID numbers, and employee files.
      </p>
      <p className="mb-4">
        By using the MD Voice website or the application, you consent to the use
        of personal information as described in this Privacy Policy. The
        company’s customers are healthcare practitioners who are utilizing our
        artificial intelligence voice recording service application. If you are
        a patient of a healthcare practitioner and do not consent to your
        personal information being collected and used by the Application, you
        must inform your healthcare practitioner that you do not consent.
      </p>

      {/* 10 Principles for the Protection of Personal Information */}
      <h2 className="text-xl font-semibold mb-2">
        10 Principles for the Protection of Personal Information
      </h2>
      <p className="mb-4">
        We will collect, use, store and disclose personal information in
        accordance with the following 10 privacy principles:
      </p>

      {/* 1. Accountability */}
      <h3 className="text-lg font-semibold mb-2">1. Accountability</h3>
      <p className="mb-4">
        The overall responsibility for ensuring our compliance with data privacy
        laws and this privacy policy rests with our Privacy Officer, although
        other individuals within the Company have responsibility for the
        day-to-day collection and processing of personal information and may be
        delegated to act on behalf of the Privacy Officer.
      </p>
      <p className="mb-4">
        We are responsible for personal information in our possession or
        custody, including personal information that we may transfer to third
        parties for processing. We will require our third parties to agree to
        contractual requirements or other means that provide a comparable level
        of protection while personal information is being processed.
      </p>
      <p className="mb-4">
        Our Privacy Office will receive and respond to complaints and inquiries.
        If you would like to contact our Privacy Officer, please email the
        Company at:{" "}
        <a href="mailto:support@mdvoice.ai" className="text-blue-500 underline">
          support@mdvoice.ai
        </a>
        .
      </p>

      {/* 2. Identifying Purposes */}
      <h3 className="text-lg font-semibold mb-2">2. Identifying Purposes</h3>
      <p className="mb-4">
        Before or at the time of collection, we will identify the purposes for
        which we plan to use the personal information. Depending upon the way in
        which the personal information is collected, this can be done orally or
        in writing.
      </p>
      <p className="mb-4">
        The Company may use the personal information we collect for the
        following purposes:
      </p>
      <ul className="list-disc list-inside mb-4 space-y-1">
        <li>to send email to users with a user account;</li>
        <li>
          to select and announce the winner of the monthly sweepstakes draw;
        </li>
        <li>
          to respond to customer inquiries about accounts and other services;
        </li>
        <li>to create user account profiles; and</li>
        <li>to meet legal requirements.</li>
      </ul>
      <p className="mb-4">
        The Company will only collect personal information that is necessary for
        the above purposes. Unless required by law, we will not use personal
        information for a new purpose without the knowledge and consent of the
        individual to whom the information relates. The Company will maintain a
        record of all identified purposes and consents obtained.
      </p>

      {/* 3. Consent */}
      <h3 className="text-lg font-semibold mb-2">3. Consent</h3>
      <p className="mb-4">
        Personal information will only be collected, used or disclosed with the
        express or implied consent of the individual, except in certain
        circumstances permitted or required by law. The way in which we seek
        consent may vary depending upon the sensitivity of the information. We
        will obtain consent in all cases where the personal information involved
        is considered sensitive, such as income or health information.
      </p>
      <p className="mb-4">
        Typically, we will seek consent for the use or disclosure of personal
        information at the time of collection when a user accesses the Company’s
        website or application and thereby accepts the applicable terms and
        conditions. However, additional consent will be sought after the
        personal information has been collected if it is required for a new
        purpose other than that stated under the applicable Company terms and
        conditions. The terms and conditions allow users to withdraw consent of
        having their names disclosed in the event they win the monthly
        sweepstakes draw by emailing the Company at{" "}
        <a href="mailto:support@mdvoice.ai" className="text-blue-500 underline">
          support@mdvoice.ai
        </a>{" "}
        or adjusting their settings on their user account. The terms and
        conditions will also require that any and all users of the Company
        website and application are at least the age of majority and thus can
        provide meaningful consent for themselves.
      </p>
      <p className="mb-4">
        In certain circumstances, obtaining consent would be inappropriate. The
        Canadian PIPEDA, US HIPAA, and provincial/state privacy laws provide for
        exceptions where it is impossible or impractical to obtain consent.
      </p>

      {/* 4. Limiting Collection */}
      <h3 className="text-lg font-semibold mb-2">4. Limiting Collection</h3>
      <p className="mb-4">
        We will collect personal information by fair and lawful means and will
        limit the amount and type of personal information we collect to that
        which is necessary for our identified purposes. The personal information
        that will be required for users to create a user account are the
        following: name, email, and phone number. Users can optionally input
        more information onto their user accounts.
      </p>

      {/* 5. Limiting Use, Disclosure and Retention */}
      <h3 className="text-lg font-semibold mb-2">
        5. Limiting Use, Disclosure and Retention
      </h3>
      <p className="mb-4">
        We will not use or disclose personal information for purposes other than
        those for which it was collected, except with the consent of the
        individual or as required by law. Any new purpose for using personal
        information shall be documented by the Company.
      </p>
      <p className="mb-4">
        The Company may sell and/or trade customer lists to selected third-party
        companies and organizations that meet our requirement for security and
        confidentiality. These lists will not include any personal information
        and do not include credit or transaction information. Subject to any
        applicable business, legal, or regulatory requirements, we will ensure
        that the data is destroyed in a secure manner, erased or made anonymous.
      </p>
      <p className="mb-4">
        The Company’s medical professional clients will access and share
        personal health information and patient records to and from the
        Electronic Medical Records via a Chrome extension. Any transfer of
        information or data to and from the Chrome extension shall require
        authorization from the Company’s medical professional clients using
        Section-Based Access credentials.
      </p>
      <p className="mb-4">
        For clarity, by using the Chrome extension for data transfers, the
        Company’s medical professional clients agree to comply with all
        stipulated authorization procedures and safeguards established by the
        Company. Failure to comply with these guidelines may result in the
        suspension or termination of access to the Chrome extension and
        potential legal action to address any breach of this clause or related
        damages arising from such non-compliance.
      </p>
      <p className="mb-4">
        The Company disclaims all liability for any unauthorized access to, or
        use of, personal health information that results from the failure of its
        medical professional clients to properly secure their Section-Based
        Access credentials or to follow the authorized data transfer procedures
        as outlined in this clause. It is the responsibility of the medical
        professional clients to ensure that their use of the Chrome extension
        for data transfers complies with this clause and protects the privacy
        and security of personal health information at all times.
      </p>
      <p className="mb-4">
        Personal information will be retained for as long as necessary for the
        fulfillment of the purposes described in section 2.
      </p>
      <p className="mb-4">
        Upon an employee’s resignation, retirement or termination of employment,
        the employee’s personal information will be destroyed in a secure manner
        and in accordance with applicable privacy legislation.
      </p>
      <p className="mb-4">
        The Company may provide personal information to affiliates. Our
        affiliates are the family of companies that form our parent company and
        any/all sister companies. The full list of such companies can be found
        on our parent company website at{" "}
        <a href="mailto:support@mdvoice.ai" className="text-blue-500 underline">
          support@mdvoice.ai
        </a>
        .
      </p>

      {/* 6. Accuracy */}
      <h3 className="text-lg font-semibold mb-2">6. Accuracy</h3>
      <p className="mb-4">
        We will use our best efforts to ensure that personal information that is
        used on an ongoing basis and information that is used to make a decision
        about an individual is as accurate, complete, and up-to-date as
        necessary for the purpose for which it is to be used. Any routine
        updates to personal information will only be conducted if necessary to
        fulfill the purposes for which the information was collected.
      </p>

      {/* 7. Safeguards */}
      <h3 className="text-lg font-semibold mb-2">7. Safeguards</h3>
      <p className="mb-4">
        We will protect personal information with safeguards appropriate to the
        level of sensitivity of the information. Our safeguards protect personal
        information against loss or theft, as well as unauthorized access,
        disclosure, copying, use or modification, regardless of the format in
        which the information is held. Our methods of protection include
        physical measures, organizational measures, and technological measures.
      </p>
      <p className="mb-4">
        Personal information of users will be stored on a database on Google
        servers located in Canada; all personal information will remain in
        Canada. The personal information on the database will be protected by
        end-to-end encryption on a password-protected computer. The computer
        will be secured in a locked room. Only the Privacy Officer and senior
        management have authorization to access the database in which the
        personal information records are being retained. Personal information
        transferred via the Chrome extension between medical professionals and
        the Electronic Medical Records will require authorization from medical
        professionals using “Section-Based Access” credentials.
      </p>
      <p className="mb-4">
        We will exercise care in the disposal or destruction of personal
        information to prevent unauthorized parties from gaining access to the
        information. The Company’s employees will be made aware of the
        importance of maintaining confidentiality of personal information. We
        also require our outside service providers to provide a comparable level
        of protection to personal information that we may supply to them.
      </p>

      {/* 8. Openness */}
      <h3 className="text-lg font-semibold mb-2">8. Openness</h3>
      <p className="mb-4">
        The Company will make readily available specific information about its
        personal information management policies and practices. To access more
        information about the Company’s policies and practices, please email{" "}
        <a href="mailto:support@mdvoice.ai" className="text-blue-500 underline">
          support@mdvoice.ai
        </a>
        .
      </p>

      {/* 9. Individual Access */}
      <h3 className="text-lg font-semibold mb-2">9. Individual Access</h3>
      <p className="mb-4">
        Upon written request, we will inform an individual of the existence, use
        and disclosure of their personal information and give them reasonable
        access to that information. We may deny access for legally permissible
        reasons, such as situations where the information is prohibitively
        costly to provide, if it contains references to other individuals, or
        where it cannot be disclosed for legal, security or commercial
        proprietary reasons. We will advise the individual of any reason for
        denying an access request.
      </p>
      <p className="mb-4">
        When an individual successfully demonstrates the inaccuracy or
        incompleteness of personal information held by us, we will correct or
        update the information as required.
      </p>

      {/* 10. Filing Inquiries and Complaints */}
      <h3 className="text-lg font-semibold mb-2">
        10. Filing Inquiries and Complaints
      </h3>
      <p className="mb-4">
        We will investigate all written complaints and respond to all written
        inquiries. If we find a complaint to be justified, we will take
        appropriate measures to resolve it. If you would like to file a
        complaint with the Company, please contact the Privacy Officer by
        emailing{" "}
        <a href="mailto:support@mdvoice.ai" className="text-blue-500 underline">
          support@mdvoice.ai
        </a>
        .
      </p>
      <p className="mb-4">
        To file an opt-out request, request access to your information, report
        incorrect information or file a complaint, please email{" "}
        <a href="mailto:support@mdvoice.ai" className="text-blue-500 underline">
          support@mdvoice.ai
        </a>
        .
      </p>

      {/* Privacy Policy Changes */}
      <h2 className="text-xl font-semibold mb-2">Privacy Policy Changes</h2>
      <p className="mb-4">
        The Company may update our Privacy Policy from time to time. We will
        notify you of any changes by posting the new Privacy Policy on this
        page. The Company will let you know via email and/or a prominent notice
        on our website or application, prior to the change becoming effective.
      </p>
      <p className="mb-4">
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
