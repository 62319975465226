import React,{useState} from "react";
import Pricing from "./sections/Pricing";
import Features from "./sections/Features";
import PricingModal from "../PricingModal";

export default function PricingPage() {
  // const [isModalOpen, setModalOpen] = useState(false);
  return (
    <div className="p-2">
      {/* <button
        className="bg-blue-500 text-white px-6 py-3 rounded-lg font-bold"
        onClick={() => setModalOpen(true)}
      >
        Open Pricing Modal
      </button> */}
       <h2 className="text-center text-3xl font-bold my-6">Simple, Transparent Pricing</h2>
      <p className="text-center text-gray-600 mb-6">Flexible pricing tailored for solo practitioners, groups, and advanced teams—experience <br /> seamless documentation at your pace.</p>
      <Pricing />
      <Features />
      {/* <PricingModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} /> */}

    </div>
  );
}
