import React, { useState, useEffect } from "react";
import "../../../styles/Features.css";
import Feature1 from "../../../assets/f1.png";
import Feature2 from "../../../assets/f2.png";
import Feature3 from "../../../assets/f3.png";
import Feature4 from "../../../assets/f4.png";
import Feature5 from "../../../assets/f5.png";
import Feature6 from "../../../assets/f6.png";
// import Video1 from "../../../../public/assets/video1.mp4";
// import Video2 from "../../../assets/video2.mp4";
const Features = () => {
  const [region, setRegion] = useState("US"); // Default to US
  const video1 ="https://storage.googleapis.com/mdvoice-assets/videos/video1.mp4";
  const video2 ="https://storage.googleapis.com/mdvoice-assets/videos/video2.mp4";
  // const video1 ="/assets/video1.mp4";
  // const video2 ="/assets/video2.mp4";
  useEffect(() => {
    const getUserTimeZone = () => {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (timeZone.startsWith("America/")) {
        if (
          timeZone.includes("Toronto") ||
          timeZone.includes("Vancouver") ||
          timeZone.includes("Canada")
        ) {
          setRegion("Canada");
        } else {
          setRegion("US");
        }
      } else {
        setRegion("US");
      }
    };

    getUserTimeZone();
  }, []);

  // Features array inside the component to use region
  const features = [
    {
      image: Feature2,
      heading: "Automated SOAP Notes",
      description:
        "Generate comprehensive SOAP notes instantly, allowing you to focus more on patient care and less on paperwork.",
    },
    {
      image: Feature1,
      heading: "Mobile Apps",
      description: "Provided both iOS and Android Apps for maximum convenience.",
    },
    {
      image: Feature2,
      heading: "Multiple Languages",
      description:
        "Have the option of choosing from multiple different languages, whichever suits your need best.",
    },
    {
      image: Feature4,
      heading: "Unlimited Recordings",
      description:
        "Enjoy the freedom to record as many conversations as you need without any limits.",
    },
    {
      image: Feature1,
      heading: "Live Transcription",
      description:
        "Capture every detail with real-time transcription of doctor-patient conversations. No more missed information.",
    },
    {
      image: Feature5,
      heading: "Maintain History",
      description: "Track and analyze transcription and note-taking efficiency.",
    },
    {
      image: Feature6,
      heading: "Cost Savings",
      description:
        "Benefit from a reduced rate when multiple doctors join using the same group code.",
    },
    {
      image: Feature3,
      heading: `Hosted in ${region}`,
      description:
        `End-to-end encryption. HIPAA Compliant. Hosted in ${region} to ensure the highest level of security and confidentiality.`,
    },
  ];

  const featureChunks = [];
  for (let i = 0; i < features.length; i += 4) {
    featureChunks.push(features.slice(i, i + 4));
  }

  return (
    <div className="my-10 flex flex-col items-center gap-4">
      <p className="text-center text-base text-customRed-text font-semibold">
        Features
      </p>
      <p className="text-center text-4xl font-semibold">
        Unleash the Power of MD Voice
      </p>
      <p className="text-center text-xl mt-2 text-customBlack-secondary md:w-[47%]">
        Streamline Your Workflow with Cutting-Edge Transcription and Note-Taking Features
      </p>
      
      <div className="grid gap-20 mt-10 xl:p-10">
        {/* Videos for mobile and tablet view */}
        <div className="block xl:hidden space-y-4">
          <VideoComponent videoUrl={video1} />
          <VideoComponent videoUrl={video2} />
        </div>

        {/* Existing layout for desktop */}
        {featureChunks.map((featureChunk, index) => (
          <div key={index} className="xl:flex w-full items-center">
            {index === 1 && (
              <div className="hidden xl:block xl:w-1/2">
                <VideoComponent videoUrl={video1} />
              </div>
            )}

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-10 xl:w-1/2 items-center justify-center">
              {featureChunk.map((feature, i) => (
                <Feature key={i} {...feature} />
              ))}
            </div>

            {index === 0 && (
              <div className="hidden xl:block xl:w-1/2">
                <VideoComponent videoUrl={video2} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Feature = ({ image, heading, description }) => {
  return (
    <div className="text-center flex flex-col items-center">
      <img src={image} alt={heading} className="mx-auto mb-4" />
      <h3 className="text-xl font-bold">{heading}</h3>
      <p className="mt-2 md:w-[65%]">{description}</p>
    </div>
  );
};

const VideoComponent = ({ videoUrl }) => {
  return (
    <div className="flex justify-center items-center">
      <video controls playsInline autoPlay muted className="w-full h-auto">
        <source src={videoUrl} type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Features;
