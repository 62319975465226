import React, { useEffect, useState } from "react";
import FileUploader from "./FileUploader";
import SaveFile from "./SaveFile";
import axios from "axios";
import { auth } from "../firebase";
import { toast } from "react-toastify";
import { PrimaryButton } from "../components/Global/Button";
interface Template {
  id: string;
  name: string;
}

export default function CustomTemplateDialog({
  handleCloseCustomModal,
  handleSelectedCustomTemplate,
}) {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [fileContent, setFileContent] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [originalFileName, setOriginalFileName] = useState<string>("");
  const [templates, setTemplates] = useState<Template[] | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  );
  console.log(
    "🚀 ~ CustomTemplateDialog ~ selectedTemplateId:",
    selectedTemplate
  );

  useEffect(() => {
    const getTemplates = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          // throw new Error("User not authenticated");
          toast.error("User not authenticated");
        }
        const token = await user.getIdToken();

        const response = await axios.get(
          `${process.env.REACT_APP_BE_DOMAIN}/doctor-template`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTemplates(response.data.doctorTemplates);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    getTemplates();
  }, []);

  const handleRadioChange = (template: { id: string; name: string }) => {
    setSelectedTemplate(template);
  };

  const handleSave = () => {
    if (!selectedTemplate) {
      alert("Please select a template first.");
      return;
    }
    handleSelectedCustomTemplate({ id: selectedTemplate });
    handleCloseCustomModal();
    // If you want to pass the selected template ID back to a parent or do an API call:
    // if (onSaveSelected) {
    // } else {
    //   console.log("Selected template ID:", selectedTemplateId);
    //   // Or you can make an axios.post call here
    // }
  };

  const handleOpenUploadModal = () => {
    setShowUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
  };

  const handleAddTemplate = async (fileContent: string, fileName: string) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        // throw new Error("User not authenticated");
        toast.error("User not authenticated");
      }
      const token = await user.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/doctor-template`,
        {
          template: fileContent,
          name: fileName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowUploadModal(false);
      setTemplates([...templates, response.data.doctorTemplate]);
      toast.success("Template added successfully");
    } catch (e) {
      console.log("error", e);
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
      <div className="bg-white rounded shadow-md p-6 w-full max-w-md relative">
        <h2 className="text-xl font-semibold mb-4">Saved Templates</h2>

        <ul className="mb-6 max-h-32 overflow-y-auto">
          {templates?.map((template) => (
            <li key={template.id} className="mb-2 flex items-center">
              <input
                type="radio"
                name="selectedTemplate"
                id={`template-${template.id}`}
                value={template.id}
                checked={selectedTemplate?.id === template.id}
                onChange={() => handleRadioChange(template)}
                className="mr-2"
              />
              <label htmlFor={`template-${template.id}`}>{template.name}</label>
            </li>
          ))}
        </ul>
        <div className="py-2">
          <PrimaryButton
            label={"Select Template"}
            onClick={handleSave}
            isDisabled={!selectedTemplate?.id}
          />
        </div>

        <div className=" border-b last:border-b-0"></div>
        <FileUploader
          handleUploadModal={handleOpenUploadModal}
          setFileContent={setFileContent}
          setFileName={setFileName}
          setOriginalFileName={setOriginalFileName}
        />

        <button
          onClick={handleCloseCustomModal}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
        >
          ✕
        </button>
      </div>

      {showUploadModal && (
        <SaveFile
          fileContent={fileContent}
          fileName={fileName}
          setFileName={setFileName}
          handleCloseUploadModal={handleCloseUploadModal}
          originalFileName={originalFileName}
          handleAddTemplate={handleAddTemplate}
        />
      )}
    </div>
  );
}
