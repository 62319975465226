import React, { useEffect, useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import useUserStore from "../utils/userStore";
import {
  FaHome,
  FaLayerGroup,
  FaCog,
  FaClock,
  FaSignOutAlt,
} from "react-icons/fa";
import { ImPriceTag } from "react-icons/im";
import Logo from "../assets/logo-img.png";
import { auth } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
interface SidebarProps {
  // Add any props you need, e.g. user info
}

const Sidebar: React.FC<SidebarProps> = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId");
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const currentUser = useUserStore((state) => state.currentUser);
  const setCurrentUser = useUserStore((state) => state.setCurrentUser);
  const setUid = useUserStore((state) => state.setUid);

  useEffect(() => {
    setUid(userId);
  }, [setUid, userId]);

  useEffect(() => {
    const getUserData = async () => {
      const user = auth.currentUser;
      if (!user) {
        // throw new Error("User not authenticated");
        toast.error("User not authenticated");
        return false;
      }
      const token = await user.getIdToken();

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BE_DOMAIN}/user`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data) {
          const user = response.data;
          setCurrentUser(user);

          const words = user.name?.trim().split(/\s+/);
          const initials = words
            ?.map((w: string) => w[0].toUpperCase())
            .join("");
          setUsername(initials);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getUserData();
  }, [userId, setCurrentUser]);

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate("/login");
      console.log("Logged out successfully");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <>
      {/* --- DESKTOP NAV --- */}

      <div
        className="
      hidden md:flex
      flex-col
      justify-between
      w-20
      bg-customBlue-bg
    "
      >
        <div>
          <div className="p-4 flex items-center justify-center">
            <img src={Logo} alt="Logo" />
          </div>

          <nav className="mt-6 flex flex-col items-center text-customBlack-text">
            <ul>
              <li
                className="px-4 py-4 hover:bg-customBlue-bgHover flex items-center cursor-pointer"
                onClick={() => navigate("/main")}
              >
                <FaHome className="h-5 w-5" />
              </li>
              <li
                onClick={() => navigate("/main")}
                className="px-4 py-4 hover:bg-customBlue-bgHover flex items-center cursor-pointer"
              >
                <FaClock className="h-5 w-5" />
              </li>
              <li
                className="px-4 py-4 hover:bg-customBlue-bgHover flex items-center cursor-pointer"
                onClick={() => navigate("/pricing")}
              >
                <ImPriceTag className="h-5 w-5" />
              </li>
              {/* <li className="px-4 py-4 hover:bg-customBlue-bgHover flex items-center cursor-pointer">
                <FaLayerGroup className="h-5 w-5" />
              </li> */}
            </ul>
          </nav>
        </div>

        {/* Bottom section (Settings & Logout) */}
        <div className="mb-4 flex flex-col items-center text-customBlack-text">
          <ul>
            <li
              className="px-4 py-4 hover:bg-customBlue-bgHover flex items-center cursor-pointer"
              onClick={() => navigate("/settings")}
            >
              <FaCog className="h-5 w-5" />
            </li>
            <li
              className="px-4 py-4 hover:bg-customBlue-bgHover flex items-center cursor-pointer"
              onClick={handleLogout}
            >
              <FaSignOutAlt className="h-5 w-5" />
            </li>
          </ul>
        </div>
      </div>

      {/* --- MOBILE BOTTOM NAV --- */}
      <div className="block md:hidden fixed bottom-0 left-0 right-0 bg-customBlue-bg z-50">
        <div className="flex justify-evenly py-3 text-customBlack-text bg-customBlue-bg">
          <button
            onClick={() => navigate("/main", { state: { section: "home" } })}
            className="hover:bg-customBlue-bgHover p-2 rounded"
          >
            <FaHome className="h-5 w-5" />
          </button>
          <button
            onClick={() => navigate("/main", { state: { section: "history" } })}
            className="hover:bg-customBlue-bgHover p-2 rounded"
          >
            <FaClock className="h-5 w-5" />
          </button>
          <button
            onClick={() => navigate("/pricing")}
            className="hover:bg-customBlue-bgHover p-2 rounded"
          >
            <ImPriceTag className="h-5 w-5" />
          </button>
          <button
            onClick={() => navigate("/settings")}
            className="hover:bg-customBlue-bgHover p-2 rounded"
          >
            <FaCog className="h-5 w-5" />
          </button>
          <button
            onClick={handleLogout}
            className="hover:bg-customBlue-bgHover p-2 rounded"
          >
            <FaSignOutAlt className="h-5 w-5" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
