import React, { useState, DragEvent, ChangeEvent } from "react";
import mammoth from "mammoth";
import { ToastContainer, toast } from "react-toastify";
import { IoCloudUploadOutline } from "react-icons/io5";
import Upload from "../assets/upload.png";

interface FileUploaderProps {
  handleUploadModal: any;
  setFileName: any;
  setFileContent: any;
  setOriginalFileName: any;
  // You can add callback props if you want to pass file content/name to the parent
}

const FileUploader: React.FC<FileUploaderProps> = ({
  handleUploadModal,
  setFileName,
  setFileContent,
  setOriginalFileName,
}) => {
  // Mock function to demonstrate how you might call your server to convert .doc -> .docx
  // In a real app, you would implement an actual API call using fetch/axios.
  const convertDocToDocx = async (file: File): Promise<ArrayBuffer> => {
    console.warn("convertDocToDocx() called - server conversion needed!");
    return new ArrayBuffer(0); // mock
  };

  const parseFile = async (file: File) => {
    if (!file) return;

    // Validate extension
    const extension = file.name.split(".").pop()?.toLowerCase();
    if (!["txt", "doc", "docx"].includes(extension || "")) {
      toast.error("Invalid file type! Only .txt, .doc, and .docx are allowed.");
      return;
    }

    try {
      // Set the "original" file name (without extension)
      const nameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      setOriginalFileName(file.name);
      setFileName(nameWithoutExtension); // Initialize the fileName to the original

      if (extension === "txt") {
        // Read .txt files as plain text
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target?.result) {
            setFileContent(e.target.result as string);
          }
        };
        reader.readAsText(file);
      } else if (extension === "docx") {
        // Convert .docx to HTML
        const arrayBuffer = await file.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setFileContent(result.value);
      } else if (extension === "doc") {
        // --- Fallback approach (reads .doc as text, no formatting) ---
        const fallbackReader = new FileReader();
        fallbackReader.onload = (e) => {
          if (e.target?.result) {
            setFileContent(e.target.result as string);
          }
        };
        fallbackReader.readAsText(file);

        // --- OR server-based approach (comment out fallback if using server) ---
        // const arrayBuffer = await convertDocToDocx(file);
        // if (arrayBuffer.byteLength) {
        //   const result = await mammoth.convertToHtml({ arrayBuffer });
        //   setFileContent(result.value);
        // } else {
        //   alert("Server conversion failed or not implemented.");
        // }
      }
      handleUploadModal();
    } catch (error) {
      console.error("Error parsing file:", error);
      alert("Error parsing file. Check console for details.");
    }
  };

  // Drag events
  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      parseFile(file);
    }
  };

  // On file select
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      parseFile(file);
    }
  };

  return (
    <>
      <div className="p-6 max-w-xl mx-auto">
        <h1 className="text-2xl font-bold text-center">Add New Template</h1>

        <div
          className="flex flex-col items-center justify-center p-6 bg-white"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <img src={Upload} alt="Upload" />
          <label className="mt-2 inline-block bg-customBlue-secondary text-white px-6 py-2 rounded-md cursor-pointer transition-colors">
            Upload File
            <input
              type="file"
              className="hidden"
              accept=".txt, .doc, .docx"
              onChange={handleFileChange}
            />
          </label>
          <p className="text-gray-600 mt-4">Allowed file types: .docx, txt</p>
          <p className="text-gray-600">File size limit: 5 mb </p>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default FileUploader;
