import React, { useState } from "react";
import FileIcon from "../assets/file-icon.png";
import { PrimaryButton } from "./Global/Button";

export default function SaveFile({
  fileContent,
  fileName,
  setFileName,
  handleCloseUploadModal,
  originalFileName,
  handleAddTemplate,
}) {
  return (
    <div>
      {fileContent && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="bg-white rounded shadow-md p-6 w-full max-w-md relative">
            <h2 className="text-xl font-semibold mb-4">Upload Template</h2>
            <div className="flex flex-col items-center gap-2">
              <div className="">
                <img src={FileIcon} alt="File" />
              </div>
              <p className="text-gray-600">{originalFileName}</p>
            </div>
            <div className="mt-6">
              <div className="mb-4">
                <label className="block mb-1 text-left">Template Name</label>
                <input
                  type="text"
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter a new name for your file"
                />
              </div>

              {/* <h2 className="text-lg font-bold mb-2">Preview:</h2>
                <div
                  className="border p-4 bg-gray-50 min-h-[200px] rounded-md shadow-inner"
                  dangerouslySetInnerHTML={{ __html: fileContent }}
                /> */}
            </div>

            {/* Add Template Button */}
            <PrimaryButton
              label="Add Template"
              onClick={async () =>
                await handleAddTemplate(fileContent, fileName)
              }
              isDisabled={!fileName}
            />

            {/* Close modal button */}
            <button
              onClick={handleCloseUploadModal}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
