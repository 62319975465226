import React, { useState } from "react";
import TranscriptSection from "../components/Main/TranscriptSection";
import GeneratedPromptView from "../components/Main/GeneratedPromptView";

type TabOption = "transcript" | "notes" | "referrals";

export default function RecordingAreaTabs({
  selectedRecord,
  pauseRecording,
  userTranscript,
  formattedPromptSOAP,
  resetRecordingState,
  handleTranscriptChange,
  handlePause,
  handleSubmitResponse,
  formattedSOAP,
  activeTab,
  handleTabClick,
  recorderControls
}) {
  return (
    <div className="p-4">
      <div className="flex gap-4 border-b border-gray-200">
        <button
          onClick={() => handleTabClick("transcript")}
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "transcript"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-600"
          }`}
        >
          Transcript
        </button>
        <button
          onClick={() => handleTabClick("notes")}
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "notes"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-600"
          }`}
        >
          Notes
        </button>
        {/* <button
          onClick={() => handleTabClick("referrals")}
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "referrals"
              ? "text-blue-600 border-b-2 border-blue-600"
              : "text-gray-600"
          }`}
        >
          Referrals
        </button> */}
      </div>

      {/* Tab content */}
      <div className="mt-4">
        {activeTab === "transcript" && (
          <TranscriptSection
            selectedRecord={selectedRecord}
            pauseRecording={pauseRecording}
            userTranscript={userTranscript}
            formattedPromptSOAP={formattedPromptSOAP}
            //   selectedOption={selectedOption}
            //   handleOptionChange={handleOptionChange}
            resetRecordingState={resetRecordingState}
            //   setSelectedSpecialization={setSelectedSpecialization}
            handleTranscriptChange={handleTranscriptChange}
            handlePause={handlePause}
            handleSubmitResponse={handleSubmitResponse}
            recorderControls={recorderControls}

          />
        )}

        {activeTab === "notes" && (
          <div>
            <h2 className="text-lg font-semibold mb-2">Notes</h2>
            <GeneratedPromptView
              formattedPromptSOAP={formattedPromptSOAP}
              formattedSOAP={formattedSOAP}
            />
          </div>
        )}

        {activeTab === "referrals" && (
          <div>
            <h2 className="text-lg font-semibold mb-2">Referrals</h2>
            <p className="text-gray-700">
              This is the referrals content. You can list relevant referrals
              here.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
