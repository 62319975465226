import React, { useState } from "react";
import LoginImg from "../../assets/login.jpg";
import { signInWithCredential } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/Auth.css";
import Layout from "../Layout";
import axios from "axios";
import CryptoJS from "crypto-js";
import {
  auth,
  recaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
} from "../../firebase";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PasswordInput from "../Global/PasswordInput";

function ForgotPasswordForm() {
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [disableVerificationButton, setDisableVerificationButton] =
    useState(false);
  const setupRecaptcha = () => {
    try {
      window.recaptchaVerifier = recaptchaVerifier();
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    } catch (error) {
      console.error("Error setting up reCAPTCHA:", error);
    }
  };

  const sendVerificationCode = async (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(phoneNumber)) {
      toast.error("Invalid Phone number");
      return;
    }
    setDisableVerificationButton(true);

    setupRecaptcha();
    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window.recaptchaVerifier
      );

      setVerificationId(confirmationResult.verificationId);
    } catch (error) {
      console.log(error);
    }
  };

  const verifyCode = async (e) => {
    e.preventDefault();
    try {
      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      const userCredential = await signInWithCredential(auth, credential);
      const user = userCredential.user;
      const idToken = await user.getIdToken();

      const response = await fetch(
        `${process.env.REACT_APP_BE_DOMAIN}/user/verify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ idToken }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("User verified successfully:", data);
        setIsVerified(true);
      } else {
        console.error("Failed to verify user");
      }
    } catch (error) {
      console.error("Error verifying code:", error);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    const hashedNewPassword = CryptoJS.SHA256(password).toString();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/user/forgot-password`,
        {
          phoneNumber: phoneNumber,
          newPassword: hashedNewPassword,
          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `Bearer ${token}`
          },
        }
      );

      if (response.data) {
        toast.success(response.data);
        setTimeout(() => navigate("/login"), 2000);
      }
    } catch (error) {
      toast.error("Error while updating password");
    }
  };

  return (
    <>
      <div className="auth-container">
        <form
          className="auth-form md:p-2 p-4 flex flex-col gap-4"
          onSubmit={handleForgotPassword}
        >
          <div>
            <h1 className="text-h1 font-h1  text-customBlack-primary">
              Forgot Password
            </h1>
            <p className="text-p font-p text-customBlack-secondary">
              Please folow the steps to get your password changed
            </p>
          </div>
          {!isVerified && (
            <div className="flex flex-col gap-4">
              <div>
                <p>Phone Number</p>

                <PhoneInput
                  international
                  countryCallingCodeEditable={true}
                  defaultCountry="CA"
                  placeholder={"Phone number"}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  style={{
                    borderRadius: "6px",
                    paddingLeft: "10px",
                    width: "inherit",
                  }}
                />
              </div>
              <button
                onClick={sendVerificationCode}
                disabled={!!verificationId}
              >
                Send Verification Code
              </button>
              <div id="recaptcha-container"></div>
              <>
                {verificationId && (
                  <div>
                    <input
                      type="text"
                      placeholder="Verification Code"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    <button onClick={verifyCode}>Verify Code</button>
                  </div>
                )}
              </>
            </div>
          )}
          {isVerified && (
            <>
              <PasswordInput
                label="Password"
                placeholder="Enter password"
                value={password}
                handleOnChange={(e) => setPassword(e.target.value)}
                isRequired={true}
              />
              <button type="submit">Change Password</button>
            </>
          )}
          <p>
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}

const ForgotPassword = () => {
  return <Layout children={<ForgotPasswordForm />} img={LoginImg} />;
};

export default ForgotPassword;
